import type React from "react";
import { useMemo } from "react";
import { VideoWrapper } from "../../../../common/components/VideoWrapper/video-wrapper.tsx";
import { Button } from "../button/button.tsx";
import { api } from "../../helpers/api.ts";
import { useAsync } from "react-async-hook";
import { getShownMetric } from "../../helpers/metrics.ts";

import styles from "./outro.module.less";

interface Props {
  setOutroShown: () => void;
  customOutro?: string;
  customOutroPoster?: string;
}

export const Outro: React.FC<Props> = ({
  setOutroShown,
  customOutro,
  customOutroPoster,
}) => {
  const sendMetric = async () => {
    return await api.metrics.send(metric);
  };
  const metric = useMemo(() => getShownMetric("outro"), []);
  useAsync(sendMetric, [metric], {
    executeOnMount: true,
  });

  return (
    <div className={styles.outroContainer}>
      <VideoWrapper
        isVertical={false}
        poster={{
          src: customOutroPoster ? customOutroPoster : "/outroPoster.jpeg",
        }}
        video={{ src: customOutro ? customOutro : "/outro.mp4" }}
      />
      <Button onClick={setOutroShown} text={"Продолжить"} />
    </div>
  );
};
