import type { Metric } from "../../../common/types/metrics.ts";

const getTimeForMetric = () => {
  return {
    userTime: (new Date()).toLocaleString(),
    utcTime: (new Date()).toUTCString(),
  }
}

export const getShownMetric = (componentName: string): Metric => ({
  name: `${componentName}-shown`,
  value: JSON.stringify({
    ...getTimeForMetric()
  })
});

export const getStagePassedMetric = (stage: number): Metric => ({
  name: `stage-passed`,
  value: JSON.stringify({
    ...getTimeForMetric(),
    stage
  })
});

export const getHintOpenedMetric = (stage: number): Metric => ({
  name: `hint-opened`,
  value: JSON.stringify({
    ...getTimeForMetric(),
    stage
  })
});

export const getHackerDetectedMetric = (): Metric => ({
  name: "hacker-detected",
  value: JSON.stringify({
    ...getTimeForMetric()
  })
})

export const getCongratulationVersionMetric = (version: "cringe" | "not-cringe"): Metric => ({
  name: `congratulation-version`,
  value: JSON.stringify({
    ...getTimeForMetric(),
    version
  })
});
