import type { Image } from "../../../../common/types/media.ts";
import { hints } from "../images.ts";

export interface IStage {
  index: number;
  puzzle: React.ReactNode;
  hint: Image;
  availableAt: string;
  isGiftAvailable: boolean;
  isMemeAvailable: boolean;
}

export interface IQuest {
  stages: IStage[];
}

function setAvailableAt(date: Date, daysToAdd: number): Date {
  const yekaterinburgOffset = 5;
  const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
  const adjustedTime =
    utcTime + yekaterinburgOffset * 3600000 + daysToAdd * 86400000;
  const newDate = new Date(adjustedTime);
  newDate.setUTCHours(16, 0, 0, 0); // 16:00 UTC is 21:00 Yekaterinburg time
  return newDate;
}

function adjustTime(date: Date, stage: number): Date {
  switch (stage) {
    case 7: {
      date.setUTCHours(5, 0, 0, 0); // 10:00 Yekaterinburg time
      return date;
    }
    case 8: {
      date.setUTCHours(5, 0, 0, 0); // 10:00 Yekaterinburg time
      return date;
    }
    case 9: {
      date.setUTCHours(14, 0, 0, 0); // 17:00 Yekaterinburg time
      return date;
    }
  }
  return date;
}

const baseDate = new Date("2024-08-01");

export const Quest: IQuest = {
  stages: [
    {
      // [31.07 среда] Ничего, потому что билет и так уже подарок
      index: 0,
      puzzle: "",
      hint: {} as Image,
      availableAt: setAvailableAt(baseDate, 1000).toISOString(),
      isGiftAvailable: false,
      isMemeAvailable: false,
    },
    {
      // [01.08 четверг] Шоколадка?
      index: 1,
      puzzle: "!Видео && (!Южный && !Беспроводной && !Аэропорт)",
      hint: hints.stage1,
      availableAt: adjustTime(setAvailableAt(baseDate, 0), 1).toISOString(),
      isGiftAvailable: true, // Надеюсь, ты не нашла этот Kit-Kat раньше времени?
      isMemeAvailable: true,
    },
    {
      // [02.08 пятница-конфур] Тут ничего не было — можно придумать какой-то подарок
      index: 2,
      puzzle:
        "Пылесос был повержен, но ящик с инструментами оказался сильнее😧",
      hint: hints.stage2,
      availableAt: adjustTime(setAvailableAt(baseDate, 1), 2).toISOString(),
      isGiftAvailable: true,
      isMemeAvailable: true,
    },
    {
      // [03.08 суббота] Тут ничего не было — можно придумать какой-то подарок
      index: 3,
      puzzle: "В детстве я был очень стеснительным, а он и сейчас такой👉🏻👈🏻",
      hint: hints.stage3,
      availableAt: adjustTime(setAvailableAt(baseDate, 2), 3).toISOString(),
      isGiftAvailable: true,
      isMemeAvailable: true,
    },
    {
      // [04.08 воскресенье] Ваза + цветы в офисе
      index: 4,
      puzzle: '"Какое свидание твоей мечты?"',
      hint: hints.stage4,
      availableAt: adjustTime(setAvailableAt(baseDate, 3), 4).toISOString(),
      isGiftAvailable: true,
      isMemeAvailable: false,
    },
    {
      // [05.08 понедельник] Сертификат на фотосессию
      index: 5,
      puzzle: "Если бы Юта ходила в школу, то как бы она «это» носила?",
      hint: hints.stage5,
      availableAt: adjustTime(setAvailableAt(baseDate, 4), 5).toISOString(),
      isGiftAvailable: true,
      isMemeAvailable: false,
    },
    {
      // [06.08 вторник] Видео из нашего путешествия на Симиланские острова
      index: 6,
      puzzle: "Раннее утро, Андаман, Симиланы, мороженка с печенькой",
      hint: hints.stage6,
      availableAt: adjustTime(setAvailableAt(baseDate, 5), 6).toISOString(),
      isGiftAvailable: true,
      isMemeAvailable: false,
    },
    {
      // [07.08 Среда] Это саб-квест — без подарка
      index: 7,
      puzzle: (
        <span>
          - Это тебе подарок💕 Кстати, еще там есть то, что ты можешь подарить
          мне :)
          <br />- Это тебе! ... Да ладно, я шучу — вот это тебе. Я еще вот такой
          взяла — мне нравится Jim Jarmusch
          <br />- А я решил подарить этот, потому что тут больше всякого :)
        </span>
      ),
      hint: hints.stage7,
      availableAt: adjustTime(setAvailableAt(baseDate, 5), 7).toISOString(),
      isGiftAvailable: false,
      isMemeAvailable: false,
    },
    {
      // [07.08 Среда] Приглашение на ужин-свидание
      index: 8,
      puzzle: (
        <span>
          56.817464° 60.676613°
          <br /> Будет доступно после концерта
          <br />
          (И это не закладка)
        </span>
      ),
      hint: hints.stage8,
      availableAt: adjustTime(setAvailableAt(baseDate, 5), 8).toISOString(),
      isGiftAvailable: true,
      isMemeAvailable: false,
    },
    {
      // [10.08] Сертификат в SPA Today / Кружки
      index: 9,
      puzzle: "1, 3/2, 4, 13, Архангельск",
      hint: hints.stage9,
      availableAt: adjustTime(setAvailableAt(baseDate, 9), 9).toISOString(), // Поправить дату на дату ужина-свидания
      isGiftAvailable: true,
      isMemeAvailable: false,
    },
  ],
};
