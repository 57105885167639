import type React from "react";
import { getHackerDetectedMetric } from "../../helpers/metrics";
import { useMemo } from "react";
import { api } from "../../helpers/api";
import { useAsync } from "react-async-hook";

interface Props {}

const HACKER_MESSAGE =
  "А это ты хитро придумала, но у меня рассчитано, что всё будет день в день. Прости😔";

const HackerDetected: React.FC<Props> = () => {
  const metric = useMemo(() => getHackerDetectedMetric(), []);
  const sendMetric = async () => {
    await api.metrics.send(metric);
  };
  useAsync(sendMetric, [metric], {
    executeOnMount: true,
  });
  return <div style={{ height: "fit-content" }}>{HACKER_MESSAGE}</div>;
};

export default HackerDetected;
