import type React from "react";
import type {
  IDetectedBarcode,
  IScannerComponents} from "@yudiel/react-qr-scanner";
import {
  Scanner,
} from "@yudiel/react-qr-scanner";

import styles from "./scanner-wrapper.module.less"

interface Props {
  onScan?: (detectedCodes: IDetectedBarcode[]) => void;
}

export const ScannerWrapper: React.FC<Props> = ({ onScan }) => {
  return (
    <div style={{ height: "auto" }}>
      <Scanner
        components={{
          torch: false
        } as IScannerComponents}
        onScan={(detectedCodes) => {
          console.log(detectedCodes);
          onScan?.(detectedCodes);
        }}
      />
    </div>
  );
};
