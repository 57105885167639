import useLocalStorage, {
  LocalStorage,
} from "../../../common/hooks/localStorageHook.ts";

type Confirmation = "confirmed" | "declined" | "undefined";

export interface PersistentQuestState {
  stage: number;
  stageOpenedAt: Array<string>;
  isGiftClaimed: boolean[];
  isDateConfirmed: Confirmation;
}

const defaultPersistentState: PersistentQuestState = {
  stage: 1,
  stageOpenedAt: [],
  isGiftClaimed: [],
  isDateConfirmed: "undefined",
};

const LOCAL_STORAGE_KEY = "quest";

export class QuestLocalStorageHelper {
  private localStorage: LocalStorage<PersistentQuestState>;

  constructor() {
    this.localStorage = new LocalStorage(
      LOCAL_STORAGE_KEY,
      defaultPersistentState
    );
  }

  getValue() {
    return this.localStorage.getValue();
  }

  setValue(value: PersistentQuestState) {
    this.localStorage.setValue(value);
  }
}

function useQuestLocalStorage() {
  const [questState, setQuestState] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    defaultPersistentState
  );

  const setStage = (stage: number) => {
    setQuestState({
      ...questState,
      stage,
    });
  };

  const setStageOpenedAt = (stage: number, timeISO: string) => {
    const stageOpenedAt = questState.stageOpenedAt;
    stageOpenedAt[stage] = timeISO;
    setQuestState({
      ...questState,
      stageOpenedAt,
    });
  };

  const setIsGiftClaimed = (stage: number, value: boolean) => {
    const claimed = [...questState.isGiftClaimed];
    claimed[stage] = value;
    setQuestState({
      ...questState,
      isGiftClaimed: claimed,
    });
  };

  const setIsDateConfirmed = (value: Confirmation) => {
    setQuestState({ ...questState, isDateConfirmed: value });
  };

  return {
    questState,
    setStage,
    setStageOpenedAt,
    setQuestState,
    setIsGiftClaimed,
    setIsDateConfirmed,
  };
}

export default useQuestLocalStorage;
