import React, { useCallback, useState, useEffect, useMemo } from "react";
import Modal from "../../../../common/components/Modal/Modal.tsx";
import { ImageWrapper } from "../../../../common/components/ImageWrapper/image-wrapper.tsx";
import { ScannerWrapper } from "../scanner-wrapper/scanner-wrapper.tsx";
import { Button } from "../button/button.tsx";
import useQuestLocalStorage, {
  PersistentQuestState,
} from "../../helpers/questLocalStorageHelper.ts";
import { Quest } from "./stage-configuration.tsx";
import { extractQueryParams } from "../../../../common/routing/utils.ts";
import type { IDetectedBarcode } from "@yudiel/react-qr-scanner";
import { getDiffBetweenDays } from "../../../../common/utils/date.ts";
import { api } from "../../helpers/api.ts";
import { useAsync } from "react-async-hook";
import {
  getHintOpenedMetric,
  getShownMetric,
  getStagePassedMetric,
} from "../../helpers/metrics.ts";
import DateInvitation from "../date-invitation/date-invitation.tsx";
import Gift from "./gift.tsx";

import styles from "./quest.module.less";
import { VideoWrapper } from "../../../../common/components/VideoWrapper/video-wrapper.tsx";
import BigGift from "../big-gift/big-gift.tsx";

interface Props {
  setIsQuestCompleted: () => void;
  isAdmin?: boolean;
}

export const QuestView: React.FC<Props> = ({
  setIsQuestCompleted,
  isAdmin,
}) => {
  const shownMetric = useMemo(() => getShownMetric("quest"), []);
  const sendShownMetric = async () => {
    return await api.metrics.send(shownMetric);
  };
  useAsync(sendShownMetric, [shownMetric], {
    executeOnMount: true,
  });

  const {
    questState,
    setQuestState,
    setStage,
    setStageOpenedAt,
    setIsGiftClaimed,
  } = useQuestLocalStorage();
  const [isHintOpened, setIsHintOpened] = useState(false);
  const [stageIndex, setStageIndex] = useState(questState.stage);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hintBlocked, setHintBlocked] = useState(true);
  const [countdown, setCountdown] = useState<string>("");

  const sendHintOpenedMetric = async () => {
    if (!isHintOpened) return;
    const metric = getHintOpenedMetric(stageIndex);
    await api.metrics.send(metric);
  };

  useAsync(sendHintOpenedMetric, [isHintOpened, stageIndex]);

  const [sendMetric, setSendMetric] = useState(false);
  const sendStagePassedMetric = async () => {
    if (!sendMetric) return;
    const metric = getStagePassedMetric(stageIndex - 1);
    await api.metrics.send(metric);
    setSendMetric(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsync(sendStagePassedMetric, [sendMetric]);

  const getStageFromUrl = (url: string) => {
    const params = extractQueryParams(url);
    return params["stage"] ? parseInt(params["stage"]) : undefined;
  };

  const changeStageInUrl = (stage: number) => {
    window.location.hash = window.location.hash.replace(
      /stage=[-]{1,}\d+|stage=\d+/,
      `stage=${stage}`
    );
  };

  const initStageInUrl = (stage: number) => {
    const params = extractQueryParams(window.location.href);
    if (Object.keys(params).includes("stage")) {
      changeStageInUrl(stage);
      return;
    }
    if (window.location.hash.includes("?")) {
      window.location.hash += `&stage=${stage}`;
    } else {
      window.location.hash += `?stage=${stage}`;
    }
  };

  const stageFromUrl = getStageFromUrl(window.location.href);

  if (!stageFromUrl || stageFromUrl <= 0) {
    const stageForUrl =
      questState.stage || questState.stage === 0 ? 1 : questState.stage;
    initStageInUrl(stageForUrl);
  }

  if (stageFromUrl !== questState.stage) {
    if (questState.stage <= 0) setStage(1);
    changeStageInUrl(questState.stage <= 0 ? 1 : questState.stage);
  }

  useEffect(() => {
    if (stageFromUrl === 0) {
      changeStageInUrl(1);
      setStage(stageFromUrl);
    }
    // eslint-disable-next-line
  }, [stageFromUrl]);

  const isStageAvailable = useCallback(
    (index: number) => {
      if (
        stageIndex !== 0 &&
        !questState.isGiftClaimed[stageIndex - 1] &&
        Quest.stages[stageIndex - 1].isGiftAvailable
      )
        return false;
      if (index > 8 && !questState.isDateConfirmed) return false;
      // if (index === 8 && dateInvitation === undefined) return false;
      return (
        currentDate >= new Date(Quest.stages[index].availableAt) || isAdmin
      );
    },
    // eslint-disable-next-line
    [currentDate, isAdmin]
  );

  const updateStage = useCallback(
    (newStageIndex: number) => {
      setSendMetric(true);
      setStageIndex(newStageIndex);
      setStage(newStageIndex);
      setHintBlocked(true);
    },
    [setStage]
  );

  const calculateCountdown = (availableAt: Date) => {
    const now = new Date();
    const difference = availableAt.getTime() - now.getTime();

    if (difference <= 0) return "";

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return `${hours}ч ${minutes}м ${seconds}с`;
  };

  useEffect(() => {
    if (stageIndex === stageFromUrl! - 1) updateStage(stageFromUrl!);
  }, [stageIndex, stageFromUrl, updateStage]);

  useEffect(() => {
    if (questState.stage > stageIndex) updateStage(questState.stage);
    if (questState.isDateConfirmed === "undefined") return;
    if (
      stageIndex ===
        (questState.isDateConfirmed === "confirmed"
          ? Quest.stages.length
          : 9) &&
      (!Quest.stages[stageIndex - 1].isGiftAvailable ||
        questState.isGiftClaimed[stageIndex - 1]) &&
      questState.isDateConfirmed === "declined"
    ) {
      setIsQuestCompleted();
    }
  }, [questState, setIsQuestCompleted, stageIndex, updateStage]);

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentDate(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (stageIndex > Quest.stages.length - 1) return;
    const timeISO = questState.stageOpenedAt[stageIndex];
    if (timeISO) {
      const time = new Date(timeISO);
      const diff = getDiffBetweenDays(time, new Date());
      if (diff <= 0) {
        setHintBlocked(false);
      }
    } else {
      if (
        isStageAvailable(stageIndex) &&
        stageIndex !== 0 &&
        (questState.isGiftClaimed[stageIndex - 1] ||
          !Quest.stages[stageIndex - 1].isGiftAvailable)
      ) {
        const puzzleStartTime = new Date();
        puzzleStartTime.setMinutes(puzzleStartTime.getMinutes() + 5);
        setStageOpenedAt(stageIndex, puzzleStartTime.toISOString());
        setHintBlocked(true);
      }
    }
  }, [
    currentDate,
    isStageAvailable,
    questState.stageOpenedAt,
    questState.isGiftClaimed,
    setStageOpenedAt,
    stageIndex,
  ]);

  const [enforceCountdown, setEnforceCountdown] = useState(false);
  useEffect(() => {
    if (stageIndex < Quest.stages.length) {
      const intervalId = setInterval(() => {
        const nextStageAvailableAt = new Date(
          Quest.stages[stageIndex].availableAt
        );
        setCountdown(calculateCountdown(nextStageAvailableAt));
      }, 1000);

      return () => clearInterval(intervalId);
    }
    setCountdown("");
  }, [stageIndex, enforceCountdown]);

  if ((stageIndex === 1 || stageIndex === 0) && !enforceCountdown) {
    setEnforceCountdown(true);
  }

  const onScanCallback = useCallback((codes: IDetectedBarcode[]) => {
    const eligibleBarcode = codes.find(
      (code) =>
        code.rawValue.includes("prelerstochka") &&
        code.rawValue.includes("stage")
    );
    if (eligibleBarcode) {
      const nextStage = getStageFromUrl(eligibleBarcode.rawValue);
      if (getStageFromUrl(window.location.href) === nextStage) {
        changeStageInUrl(nextStage! + 1);
      }
    }
  }, []);

  const getMemeView = () => {
    const index = stageIndex - 1;
    const getVideo = () => {
      switch (index) {
        case 0:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme0.mp4" }}
              poster={{ src: "/memes/meme0Poster.jpeg" }}
            />
          );
        case 1:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme1.mp4" }}
              poster={{ src: "/memes/meme1Poster.jpeg" }}
            />
          );
        case 2:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme2.mp4" }}
              poster={{ src: "/memes/meme2Poster.jpeg" }}
            />
          );
        case 3:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme3.mp4" }}
              poster={{ src: "/memes/meme3Poster.jpeg" }}
            />
          );
        case 4:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme4.mp4" }}
              poster={{ src: "/memes/meme4Poster.jpeg" }}
            />
          );
        case 5:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme5.mp4" }}
              poster={{ src: "/memes/meme5Poster.jpeg" }}
            />
          );
        case 6:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme6.mp4" }}
              poster={{ src: "/memes/meme6Poster.jpeg" }}
            />
          );
        case 7:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme7.mp4" }}
              poster={{ src: "/memes/meme7Poster.jpeg" }}
            />
          );
        case 8:
          return (
            <VideoWrapper
              video={{ src: "/memes/meme8.mp4" }}
              poster={{ src: "/memes/meme8Poster.jpeg" }}
            />
          );
        default:
          return <div style={{ display: "none" }}></div>;
      }
    };

    return getVideo();
  };

  const getGiftView = () => {
    const index = stageIndex - 1;
    switch (index) {
      case 0:
        return <div style={{ display: "none" }}></div>; // [31.07 среда] Ничего, потому что билет и так уже подарок
      case 1:
        return (
          <Gift
            text="Надеюсь, ты не нашла этот Kit-Kat раньше времени?"
            setGiftClaimed={() => setIsGiftClaimed(index, true)}
            isEnabled={countdown === ""}
          />
        ); // [01.08 четверг] Шоколадка?
      case 2:
        return (
          <Gift
            text=""
            setGiftClaimed={() => setIsGiftClaimed(index, true)}
            isEnabled={countdown === ""}
          />
        ); // [02.08 пятница-конфур] Тут ничего не было — можно придумать какой-то подарок
      case 3:
        return (
          <Gift
            text=""
            setGiftClaimed={() => setIsGiftClaimed(index, true)}
            isEnabled={countdown === ""}
          />
        ); // [03.08 суббота] Тут ничего не было — можно придумать какой-то подарок
      case 4:
        return (
          <Gift
            text="Твой подарок будет ждать тебя в офисе🌸"
            setGiftClaimed={() => setIsGiftClaimed(index, true)}
            isEnabled={countdown === ""}
          />
        ); // [04.08 воскресенье] Ваза + цветы в офисе
      case 5:
        return (
          <Gift
            text="Конверт будет у тебя на столе💌"
            setGiftClaimed={() => setIsGiftClaimed(index, true)}
            isEnabled={countdown === ""}
          />
        ); // [05.08 понедельник] Шоколадка?
      case 6:
        return (
          <BigGift
            index={index}
            setIsGiftClaimed={setIsGiftClaimed}
            countdown={countdown}
          />
        ); // [06.08 вторник] Видео Симиланы
      case 7: // [07.08 среда]
        return (
          <div>
            Кажется, я забыл добавить сюда подарок🥲
            <br />
            (7)
          </div>
        );
      case 8:
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DateInvitation
                currentResponse={questState.isDateConfirmed}
                setInvitationResponse={(val) => {
                  const claimed = [...questState.isGiftClaimed];
                  claimed[stageIndex - 1] = true;
                  const state = {
                    ...questState,
                    isGiftClaimed: claimed,
                    isDateConfirmed: val ? "confirmed" : "declined",
                  } as PersistentQuestState;
                  setQuestState(state);
                }}
              />
            </div>
          </div>
        );
      case 9:
        return (
          <Gift
            text="Кажется, ты уже получила подарок🤔"
            setGiftClaimed={() => setIsGiftClaimed(index, true)}
            isEnabled={countdown === ""}
          />
        );
    }
  };

  const getAvailableView = () => {
    if (isStageAvailable(stageIndex)) {
      return (
        <div className={styles.container}>
          <div className={styles.topContainer}>
            {Quest.stages[stageIndex].puzzle}
          </div>
          <div className={styles.middleContainer}>
            <ScannerWrapper onScan={onScanCallback} />
          </div>
          <div className={styles.bottomContainer}>
            <Button
              onClick={() => setIsHintOpened(true)}
              text={hintBlocked ? "Будет доступно через 5 минут" : "Сдаешься?"}
              isDisabled={hintBlocked}
            />
            <Modal
              isOpen={isHintOpened}
              onRequestClose={() => setIsHintOpened(false)}
            >
              <ImageWrapper image={Quest.stages[stageIndex].hint} />
            </Modal>
          </div>
        </div>
      );
    } else if (stageIndex < Quest.stages.length) {
      return (
        <div
          style={{
            padding: "0px 30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "unset",
          }}
        >
          {countdown && (
            <h2 style={{ fontSize: "large" }}>
              До следующего уровня: {countdown}
            </h2>
          )}
          {Quest.stages[stageIndex - 1].isMemeAvailable && getMemeView()}
          {Quest.stages[stageIndex - 1].isGiftAvailable && getGiftView()}
        </div>
      );
    } else if (
      Quest.stages[stageIndex - 1].isGiftAvailable &&
      !questState.isGiftClaimed[stageIndex - 1]
    ) {
      return getGiftView();
    }
  };

  return getAvailableView();
};
