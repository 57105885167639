import type React from "react";
import { useMemo } from "react";
import { Button } from "../button/button.tsx";
import { api } from "../../helpers/api.ts";
import { useAsync } from "react-async-hook";
import { getShownMetric } from "../../helpers/metrics.ts";

interface Props {
  setConfidentialShown: () => void;
}

const OPEN_IN_SAFARI_MESSAGE = "К сожалению, этот браузер не поддерживается. Открой, пожалуйста, в Safari";
const CAMERA_USE_MESSAGE = "Дальше будет запрошен доступ к камере для сканирования дальнейших кодов прямо из квеста";

export const Confidential: React.FC<Props> = ({ setConfidentialShown }) => {
  const isSafari = !/GSA/.test(window.navigator.userAgent); // Chrome|

  const sendMetric = async () => {
    return await api.metrics.send(metric);
  };
  const metric = useMemo(() => getShownMetric("confidential"), []);
  useAsync(sendMetric, [metric], {
    executeOnMount: true,
  });

  return (
    <div
      className={"content-center items-center text-center relative flex-col"}>
      <span>{isSafari ? CAMERA_USE_MESSAGE : OPEN_IN_SAFARI_MESSAGE}</span><br />

      <Button text={"Продолжить"}
              isDisabled={!isSafari}
              onClick={() => setConfidentialShown()} />
    </div>
  );
};
