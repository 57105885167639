import React, { useState } from "react";
import { AdministrationInfo } from "../../models/administration-info.ts";
import { useAsyncCallback } from "react-async-hook";
import { Button } from "../button/button.tsx";
import { api } from "../../helpers/api.ts";

interface Props {}

const Administration: React.FC<Props> = () => {
  const [info, setInfo] = useState<AdministrationInfo | undefined>(undefined);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);

  const getInfo = async () => {
    const response = await api.administration.getInfo();
    if (response.isSuccess) {
      setInfo(response.result);
    }
  };

  const getInfoOnClick = useAsyncCallback(getInfo);

  const cleanup = async () => {
    if (!accessToken) return;
    const response = await api.administration.cleanup(
      encodeURIComponent(accessToken)
    );
    if (response.isSuccess) {
      setInfo(undefined);
    }
  };

  const cleanupOnClick = useAsyncCallback(cleanup);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const getAccessToken = async () => {
    if (!password) return;

    const response = await api.administration.getAccessToken(password);
    if (response.isSuccess) {
      setAccessToken(response.result);
    }
  };

  const getAccessTokenOnClick = useAsyncCallback(getAccessToken);

  if (!info) {
    return (
      <div style={{ background: "black" }}>
        <span>No information available.</span>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button text={"Get Access"} onClick={getAccessTokenOnClick.execute} />
        <Button onClick={getInfoOnClick.execute} text={"Get info"} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        textAlign: "center",
        background: "black",
      }}
    >
      <h1>Administration Info</h1>
      <Button text={"Cleanup"} onClick={cleanupOnClick.execute} />
      <hr />
      <table className="table" style={{ width: "100%" }}>
        <caption>Ratings</caption>
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">value</th>
            <th scope="col">at</th>
          </tr>
        </thead>
        <tbody>
          {info.ratings.map((rating) => (
            <tr key={rating.id}>
              <td style={{ textAlign: "center" }}>{rating.id}</td>
              <td>{rating.value}</td>
              <td>{rating.at}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <hr />
      <table className="table table-striped" style={{ width: "100%" }}>
        <caption>Metrics</caption>
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">name</th>
            <th scope="col">value</th>
          </tr>
        </thead>
        <tbody>
          {info.metrics.map((metrics) => (
            <tr key={metrics.id}>
              <td>{metrics.id}</td>
              <td>{metrics.name}</td>
              {Object.keys(JSON.parse(metrics.value)).map((key) => (
                <tr>{`${key}: ${JSON.parse(metrics.value)[key]}`}</tr>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Administration;
