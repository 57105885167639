import React, { useState } from "react";
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MiniMap,
  Node,
  Edge,
  NodeChange,
  Connection,
  EdgeChange,
} from "reactflow";
import { Button } from "../button/button";
import { api } from "../../helpers/api.ts";

const initialNodes: Node[] = [
  {
    id: "1",
    type: "input", // input node
    data: { label: "Stage 1" },
    position: { x: 250, y: 5 },
  },
];

const initialEdges: Edge[] = [];

interface Props {}

const Constructor: React.FC<Props> = ({}) => {
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);

  const onNodesChange = (nodes: NodeChange[]) => {
    console.debug(nodes);
  };
  const onEdgesChange = (edges: EdgeChange[]) => {
    console.debug(edges);
  };
  const onConnect = (params: Connection) =>
    setEdges((eds) => addEdge(params, eds));

  const addNode = () => {
    const newNode = {
      id: (nodes.length + 1).toString(),
      data: { label: `Node ${nodes.length + 1}` },
      position: { x: Math.random() * 250, y: Math.random() * 250 },
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const saveQuest = async () => {
    try {
      // await api.quest.saveQuest({ nodes, edges });
      alert("Quest saved successfully!");
    } catch (error) {
      console.error("Failed to save quest:", error);
      alert("Failed to save quest.");
    }
  };

  return (
    <div style={{ height: "600px", width: "600px" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
      <div style={{ maxHeight: "fit-content", display: "flex" }}>
        <Button text={"Add Node"} onClick={addNode} />
        <Button text={"Save Quest"} onClick={saveQuest} />
      </div>
    </div>
  );
};

export default Constructor;
