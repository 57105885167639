import "./App.css";
import { ApplicationRouter } from "./common/routing/application-router.tsx";
import { createUser } from "./common/utils/user.ts";
import { api } from "./features/anniversary/helpers/api.ts";
import { useAsync } from "react-async-hook";

createUser();

function App() {
  const registerUser = async () => await api.user.register();
  useAsync(registerUser, [], {
    executeOnMount: true,
  });
  return (
    <div className={"app"}>
      <ApplicationRouter />
    </div>
  );
}

export default App;
