import type React from "react";
import styles from "./heart.module.less"

interface Props {
  filled: boolean;
  onClick: () => void;
}

const Heart: React.FC<Props> = ({
                                  filled,
                                  onClick,
                                }) => {
  return (
    <span onClick={onClick} className={styles.heart}>
      {filled ? "❤️" : "🤍"}
    </span>
  );
};

export default Heart;
