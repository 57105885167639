import { storage } from "./storage.ts";
import { v4 as uuidv4 } from 'uuid';

const USER_KEY = "prelerstochka.user_code"

export const createUser = () => {
  let user = storage.getItem(USER_KEY);
  if (user) return;

  user = uuidv4();
  storage.setItem(USER_KEY, user);
}

export const getUser = () => {
  return storage.getItem(USER_KEY);
}
