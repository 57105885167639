import type React from "react";
import type { Image } from "../../types/media.ts";

import styles from "./image-wrapper.module.less";
import { useEffect, useState } from "react";
import { api } from "../../../features/anniversary/helpers/api.ts";
import { useAsync } from "react-async-hook";
import Loader from "../Loader/loader.tsx";

interface Props {
  image: Image;
}

export const ImageWrapper: React.FC<Props> = ({ image }) => {
  const [imageBlob, setImageBlob] = useState<Blob | undefined>();
  const [imageUrl, setImageUrl] = useState<string>();
  const loadHint = async () => {
    const result = await api.loadAsset(image!.src);
    setImageBlob(result);
  };
  useAsync(loadHint, [image], {
    executeOnMount: true,
  });

  useEffect(() => {
    if (!imageBlob) return;
    setImageUrl(URL.createObjectURL(imageBlob));

    return URL.revokeObjectURL(imageUrl!);
  }, [imageBlob, imageUrl]);

  return (
    image && (
      <div className={styles.container}>
        {imageBlob ? (
          <img src={imageUrl} alt={""} />
        ) : (
          <Loader />
        )}
      </div>
    )
  );
};
