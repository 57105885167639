import React, { useEffect, useRef, useState } from "react";
import { World } from "../types/types.ts";

import forest from "../assets/forestWorld.png";

interface Props {
  world: World;
  vh: number;
}

export const Background: React.FC<Props> = (props) => {
  const { currentHeroPosition, start, world, end } = props.world;
  const prevHeroPosition = useRef(currentHeroPosition);
  const [currentWorldPosition, setCurrentWorldPosition] = useState(0);

  useEffect(() => {
    const deltaMove = currentHeroPosition - prevHeroPosition.current;
    setCurrentWorldPosition((prevState) => prevState + deltaMove);

    prevHeroPosition.current = currentHeroPosition;
  }, [currentHeroPosition, currentWorldPosition]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "3146px",
          height: "auto",
          left: `${start.position.x + currentHeroPosition}px`,
          zIndex: -1,
        }}
      >
        <img src={forest} alt={"forest-background"} />
      </div>
    </>
  );
};
