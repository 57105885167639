import type { LogMessage } from "../../../common/utils/logger.ts";
import type { Metric } from "../../../common/types/metrics.ts";
import { ServerTime } from "../models/server-time.ts";
import { AdministrationInfo } from "../models/administration-info.ts";
import { getUser } from "../../../common/utils/user.ts";
import WebApiClient from "../../../common/utils/webApiClient.ts";
import { BaseApi } from "../../../common/utils/base-api.ts";

const AnniversaryApi = {
  user: {
    register: (): Promise<ApiResult> => {
      return WebApiClient.post(`/api/user/register`, { code: getUser() }).then(
        (response) => response.data
      );
    },
  },
  anniversary: {
    getServerTime: (): Promise<ApiResult<ServerTime>> => {
      return WebApiClient.get<ApiResult<ServerTime>>(
        `/api/anniversary/server-time`
      ).then((response) => {
        if (response.data.result) {
          response.data.result.time = new Date(response.data.result.time);
        }
        return response.data;
      });
    },
    saveRating: (rating: number): Promise<ApiResult> => {
      return WebApiClient.post<ApiResult>(`/api/anniversary/save-rating`, {
        userCode: getUser(),
        rating,
      }).then((response) => response.data);
    },
  },
  metrics: {
    send: (metric: Metric): Promise<ApiResult> => {
      return WebApiClient.post<ApiResult>(`/api/metrics/send`, {
        ...metric,
        userCode: getUser(),
      }).then((response) => response.data);
    },
  },
  logs: {
    send: (log: LogMessage): Promise<ApiResult> => {
      return WebApiClient.post<ApiResult>(`/api/logs/send`, {
        ...log,
        userCode: getUser(),
      }).then((response) => response.data);
    },
  },
  administration: {
    getInfo: (): Promise<ApiResult<AdministrationInfo>> => {
      return WebApiClient.get<ApiResult<AdministrationInfo>>(
        `/api/administration/info`
      ).then((response) => response.data);
    },
    getAccessToken: (password: string): Promise<ApiResult<string>> => {
      return WebApiClient.get<ApiResult<string>>(
        `/api/administration/get-access-token?password=${password}`
      ).then((response) => response.data);
    },
    cleanup: (secret: string): Promise<ApiResult> => {
      return WebApiClient.post<ApiResult>(
        `api/administration/cleanup?secret=${secret}`,
        {}
      ).then((response) => response.data);
    },
  },
};

export const api = {
  ...BaseApi,
  ...AnniversaryApi,
};
