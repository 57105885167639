import useLocalStorage, {
  LocalStorage,
} from "../../../common/hooks/localStorageHook.ts";

export interface PersistentAnniversaryState {
  isWelcomeShown: boolean;
  isConfidentialShown: boolean;
  isIntroductionShown: boolean;
  isQuestCompleted: boolean;
  isOutroShown: boolean;
  isCongratulationShown: boolean;
}

export const defaultPersistentState: PersistentAnniversaryState = {
  isWelcomeShown: false,
  isConfidentialShown: false,
  isIntroductionShown: false,
  isQuestCompleted: false,
  isOutroShown: false,
  isCongratulationShown: false,
};

const LOCAL_STORAGE_KEY = "anniversary";

export class AnniversaryLocalStorageHelper {
  private localStorage: LocalStorage<PersistentAnniversaryState>;

  constructor() {
    this.localStorage = new LocalStorage(LOCAL_STORAGE_KEY, defaultPersistentState);
  }

  getValue() {
    return this.localStorage.getValue();
  }

  setValue(value: PersistentAnniversaryState) {
    this.localStorage.setValue(value);
  }
}

function useAnniversaryLocalStorage() {

  const [anniversaryState, setAnniversaryState] = useLocalStorage(LOCAL_STORAGE_KEY, defaultPersistentState);

  const setWelcomeShown = () => {
    setAnniversaryState({
      ...anniversaryState,
      isWelcomeShown: true,
    });
  };

  const setConfidentialShown = () => {
    setAnniversaryState({
      ...anniversaryState,
      isConfidentialShown: true,
    });
  };

  const setIntroductionShown = () => {
    setAnniversaryState({
      ...anniversaryState,
      isIntroductionShown: true,
    });
  };

  const setIsQuestCompleted = () => {
    setAnniversaryState({
      ...anniversaryState,
      isQuestCompleted: true,
    });
  };

  const setOutroShown = () => {
    setAnniversaryState({
      ...anniversaryState,
      isOutroShown: true,
    });
  }

  const setCongratulationShown = () => {
    setAnniversaryState({
      ...anniversaryState,
      isCongratulationShown: true,
    });
  }

  return {
    anniversaryState,
    setAnniversaryState,
    setWelcomeShown,
    setConfidentialShown,
    setIntroductionShown,
    setIsQuestCompleted,
    setOutroShown,
    setCongratulationShown,
  };
}

export default useAnniversaryLocalStorage;
