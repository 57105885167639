import React, { useMemo, useState } from "react";
import { VideoWrapper } from "../../../../common/components/VideoWrapper/video-wrapper.tsx";
import { Button } from "../button/button.tsx";
import { api } from "../../helpers/api.ts";
import { useAsync } from "react-async-hook";
import {
  getCongratulationVersionMetric,
  getShownMetric,
} from "../../helpers/metrics.ts";

import styles from "./congratulation.module.less";

interface Props {
  setCongratulationShown: () => void;
}

export const Congratulation: React.FC<Props> = ({ setCongratulationShown }) => {
  const [useCringe, setUseCringe] = useState(false);

  const handleCheckboxChange = () => {
    setUseCringe(!useCringe);
  };

  const sendShownMetric = async () => {
    return await api.metrics.send(shownMetric);
  };
  const shownMetric = useMemo(() => getShownMetric("congratulation"), []);
  useAsync(sendShownMetric, [shownMetric], {
    executeOnMount: true,
  });

  const sendVersionMetric = async () => {
    return await api.metrics.send(versionMetric);
  };
  const versionMetric = useMemo(
    () => getCongratulationVersionMetric(useCringe ? "cringe" : "not-cringe"),
    [useCringe]
  );
  useAsync(sendVersionMetric, [versionMetric], {
    executeOnMount: true,
    executeOnUpdate: true,
  });

  return (
    <div className={styles.container}>
      <VideoWrapper
        video={useCringe ? { src: "/cringe.mp4" } : { src: "/notcringe.mp4" }}
        poster={{ src: "/congratsPoster.jpeg" }}
      />
      {/* <div className={styles.checkContainer}>
        <input
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={useCringe}
        />
        Показывать кринж версию
      </div> */}
      <Button text={"Продолжить"} onClick={setCongratulationShown} />
    </div>
  );
};
