import React from "react";
import { Position, Size } from "../types/types.ts";

import asset from "../assets/collectible.png";

interface Props {
  position: Position;
  size: Size;
  isCollected: boolean;
}

export const Collectible: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `${props.position.y}px`,
        left: `${props.position.x}px`,
        width: `${props.size.width}px`,
        height: `${props.size.height}px`,
        display: props.isCollected ? "none" : "block",
      }}
    >
      <img src={asset} alt={"collectible"} width={50} height={50} />
    </div>
  );
};
