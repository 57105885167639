import React, { useEffect, useState } from "react";
import { WelcomeCountdown } from "./components/welcome-countdown.tsx";
import { Confidential } from "./components/confidential/confidential.tsx";
import { getDiffBetweenDays } from "../../common/utils/date.ts";
import { QuestView } from "./components/quest/quest-view.tsx";

import useAnniversaryLocalStorage from "./helpers/anniversaryLocalStorageHelper.ts";

import { Introduction } from "./components/introduction/introduction.tsx";
import { Congratulation } from "./components/congratulation/congratulation.tsx";
import { START_DATE } from "./constants.ts";
import { extractQueryParams } from "../../common/routing/utils.ts";
import { Outro } from "./components/outro/outro.tsx";
import Rate from "./components/rate/rate.tsx";
import { api } from "./helpers/api.ts";
import { useAsync } from "react-async-hook";
import HackerDetected from "./components/hacker-detected/HackerDetected.tsx";
import { ServerTime } from "./models/server-time.ts";
import "./styles/root.css";
import useQuestLocalStorage from "./helpers/questLocalStorageHelper.ts";
import Constructor from "./components/constructor/constructor.tsx";

export const Anniversary: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [serverTime, setServerTime] = useState<ServerTime | undefined>(
    undefined
  );
  const getServerTime = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = currentDate;
    return await api.anniversary.getServerTime();
  };
  const serverTimeResponse = useAsync(getServerTime, [currentDate], {
    executeOnMount: true,
  });

  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.update();
    }
  });

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentDate(new Date()), 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (serverTimeResponse.status === "success") {
      setServerTime(serverTimeResponse.result?.result!);
    }
  }, [serverTimeResponse.result, serverTimeResponse.status]);

  const {
    anniversaryState,
    setIntroductionShown,
    setConfidentialShown,
    setIsQuestCompleted,
    setOutroShown,
    setCongratulationShown,
    setWelcomeShown,
  } = useAnniversaryLocalStorage();
  // const { questState } = useQuestLocalStorage();
  const [countdown, setCountdown] = useState<number>(
    getDiffBetweenDays(START_DATE, new Date())
  );
  const [endingChoise, setEndingChoise] = useState<
    "undefined" | "end" | "continue"
  >("undefined");

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const daysRemaining = Math.floor(countdown / (24 * 60 * 60));
  const params = extractQueryParams(window.location.href);
  const isAdmin = params["is-admin"]?.toLowerCase() === "true";
  const isQuestAdmin = params["is-quest-admin"]?.toLowerCase() === "true";
  const isConstructor = params["is-constructor"]?.toLowerCase() === "true";

  const validateBrowserTime = (browserTime: Date, serverTime: Date) => {
    const timeDifference = Math.abs(
      browserTime.getTime() - serverTime.getTime()
    );

    const fiveMinutesInMilliseconds = 5 * 60 * 1000;
    return timeDifference > fiveMinutesInMilliseconds;
  };

  const getView = () => {
    if (isConstructor) {
      return <Constructor />;
    }
    if (
      serverTime &&
      serverTime.time &&
      validateBrowserTime(serverTime.time, new Date()) &&
      !isAdmin
    ) {
      return <HackerDetected />;
    }
    if ((daysRemaining > 0 && !isAdmin) || !anniversaryState.isWelcomeShown) {
      return (
        <WelcomeCountdown
          daysRemaining={daysRemaining}
          setWelcomeShown={setWelcomeShown}
        />
      );
    }
    if (!anniversaryState.isIntroductionShown) {
      return <Introduction setIntroductionShown={setIntroductionShown} />;
    }
    if (!anniversaryState.isConfidentialShown) {
      return <Confidential setConfidentialShown={setConfidentialShown} />;
    }
    if (!anniversaryState.isQuestCompleted) {
      return (
        <QuestView
          setIsQuestCompleted={setIsQuestCompleted}
          isAdmin={isQuestAdmin}
        />
      );
    } else {
      if (!anniversaryState.isOutroShown) {
        return <Outro setOutroShown={setOutroShown} />;
      }
      if (!anniversaryState.isCongratulationShown) {
        return (
          <Congratulation setCongratulationShown={setCongratulationShown} />
        );
      }

      switch (endingChoise) {
        case "undefined":
          return <Rate setEndingChoice={setEndingChoise} />;
        case "end":
          return <div>The End</div>;
        case "continue":
          return <div>The next chapter will begin soon</div>;
      }
    }
  };

  return <div className={"root"}>{getView()}</div>;
};
