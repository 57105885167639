import React, { useState } from "react";
import { Routes, useNavigator } from "../../common/routing/routes.tsx";
import { Button } from "../anniversary/components/button/button.tsx";
import { useAsync } from "react-async-hook";
import { BaseApi } from "../../common/utils/base-api.ts";

export const Home: React.FC = () => {
  const { navigateTo } = useNavigator();
  const navigate = (route: Routes) => {
    navigateTo(route);
  };

  const [showDebug, setShowDebug] = useState(false);
  const messageMe = useAsync(
    async () => await BaseApi.messageMe(), [], {
      executeOnMount: false,
      executeOnUpdate: false,
    }
  );

  return (
    <div>
      <div
        style={{
          backgroundColor: "black",
          position: "absolute",
          top: 0,
          left: 0,
          width: 200,
          height: 220,
          display: showDebug ? "block" : "none",
          color: "white",
        }}
      >
        <span>Debug Info</span>
        <br />
        <span>vw: {window.visualViewport?.width ?? 0}</span>
        <br />
        <span>vh: {window.visualViewport?.height ?? 0}</span>
        <br />
        <span>ih: {window.innerHeight}</span>
        <br />
        <span>iw: {window.innerWidth}</span>
        <br />
        <span>sh: {window.screen.height}</span>
        <br />
        <span>sw: {window.screen.width}</span>
        <br />
        <span>sah: {window.screen.availHeight}</span>
        <br />
        <span>saw: {window.screen.availWidth}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 20,
        }}
      >
        <span>
          Сервис сейчас в процессе переработки, но старый контент по прежнему
          доступен
        </span>
        <Button onClick={() => navigate("doge")} text={"Собачка"} />
        <Button onClick={() => navigate("anniversary")} text={"Квест"} />
        <Button
          isDisabled={messageMe.status === "loading"}
          onClick={() => messageMe.execute()}
          text={"Поговорим?"}
        />
        <Button onClick={() => setShowDebug(!showDebug)} text={"Show debug"} />
      </div>
    </div>
  );
};
