import React, { useEffect, useState } from "react";
import { Button } from "../button/button";

import styles from "./date-invitation.module.less";

interface Props {
  currentResponse: "undefined" | "confirmed" | "declined";
  setInvitationResponse: (response: boolean) => void;
}

const DateInvitation: React.FC<Props> = ({
  currentResponse,
  setInvitationResponse,
}) => {
  const [showOnlyAccept, setShowOnlyAccept] = useState(true);

  useEffect(() => {
    if (!showOnlyAccept) return;
    const timer = setInterval(() => {
      setShowOnlyAccept(false);
    }, 3000);

    return () => clearInterval(timer);
  }, [showOnlyAccept]);

  return (
    <div className={styles.container}>
      <span>Приглашаю тебя на свидание в субботу в 19:00</span>
      <div className={styles.buttonsContainer}>
        <Button
          isDisabled={currentResponse !== "undefined"}
          text={showOnlyAccept ? "Согласиться" : "Отказаться"}
          onClick={() => {
            setInvitationResponse(false);
          }}
        />
        <Button
          isDisabled={currentResponse !== "undefined"}
          text="Согласиться"
          onClick={() => {
            setInvitationResponse(true);
          }}
        />
      </div>
    </div>
  );
};

export default DateInvitation;
