import { api } from "../../features/anniversary/helpers/api.ts";

type LogLevel = "log" | "warn" | "error";

export interface LogMessage {
  level: LogLevel;
  message: any[];
  timestamp: string;
}

const originalConsole = {
  log: console.log,
  warn: console.warn,
  error: console.error,
};

const send = async (log: LogMessage) => {
  try {
    const result = await api.logs.send(log);
    if (result.isFailure) {
      originalConsole.error("Failed to save log");
    }
  } catch (error) {
    originalConsole.error("Failed to send log to server:", error);
  }
};

const createLogger = (sendLogs: boolean) => {
  const logHandler = (level: LogLevel, ...args: any[]) => {
    const logMessage: LogMessage = {
      level,
      message: args,
      timestamp: new Date().toISOString(),
    };

    if (sendLogs) send(logMessage);

    // Call the original console method
    originalConsole[level](...args);
  };

  console.log = (...args: any[]) => logHandler("log", ...args);
  console.warn = (...args: any[]) => logHandler("warn", ...args);
  console.error = (...args: any[]) => logHandler("error", ...args);
};

export default createLogger;
