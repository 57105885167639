import type React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Heart from "../heart/heart.tsx";
import { Button } from "../button/button.tsx";
import { api } from "../../helpers/api.ts";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { getShownMetric } from "../../helpers/metrics.ts";

import styles from "./rate.module.less";
import Modal from "../../../../common/components/Modal/Modal.tsx";
import { VideoWrapper } from "../../../../common/components/VideoWrapper/video-wrapper.tsx";

interface Props {
  setEndingChoice: (variant: "undefined" | "end" | "continue") => void;
}

const Rate: React.FC<Props> = ({ setEndingChoice }) => {
  const [showMeme, setShowMeme] = useState(false);

  const sendMetric = async () => {
    return await api.metrics.send(metric);
  };
  const metric = useMemo(() => getShownMetric("rate"), []);
  useAsync(sendMetric, [metric], {
    executeOnMount: true,
  });

  const [isEndingBlocked, setIsEndingBlocked] = useState(true);
  const [endingChoiseInner, setEndingChoiseInner] = useState<"undefined" | "end" | "continue">("undefined");
  const [rating, setRating] = useState(0);

  const saveRatingOnClick = async (r: number) => {
    if (rating < 5) setShowMeme(true);
    await api.anniversary.saveRating(r);
    setIsEndingBlocked(false);
  };

  const asyncOnClick = useAsyncCallback(saveRatingOnClick);

  const sendSelectedEnding = async () => {
    if (endingChoiseInner === "undefined") return;
    const metric = {
      name: "selected-ending",
      value: JSON.stringify({
        choise: endingChoiseInner,
      }),
    };
    await api.metrics.send(metric);
    setEndingChoice(endingChoiseInner);
  };

  useAsync(sendSelectedEnding, [endingChoiseInner, setEndingChoice]);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  useEffect(() => {
    if (asyncOnClick.status === "success") {
      console.log("Rating saved with result", asyncOnClick.result);
      return;
    }
    if (asyncOnClick.status === "error") {
      console.log("Failed to save rating");
      return;
    }
  }, [asyncOnClick.result, asyncOnClick.status]);

  return (
    <div className={styles.rateContainer}>
      <p>Оставь свою оценку от 1 до 5</p>
      <div className={styles.hearts}>
        {[1, 2, 3, 4, 5].map((index) => (
          <Heart
            key={index}
            filled={index <= rating}
            onClick={() => handleRating(index)}
          />
        ))}
      </div>
      <Button
        text={"Сохранить"}
        onClick={() => asyncOnClick.execute(rating)}
        isDisabled={asyncOnClick.loading}
      />

      <div className={styles.endingButtonsContainer}>
        <Button
          text={"Конец?"}
          isDisabled={isEndingBlocked}
          onClick={() => setEndingChoiseInner("end")}
        />
        <Button
          text={"Продолжение?"}
          isDisabled={isEndingBlocked}
          onClick={() => setEndingChoiseInner("continue")}
        />
      </div>

      <Modal isOpen={showMeme} onRequestClose={() => setShowMeme(false)}>
        <div className={styles.modalBodyContainer}>
          <span>А если покажу мем?</span>
          <VideoWrapper
            video={{ src: "/ratingMeme.mp4" }}
            poster={{ src: "/ratingMemePoster.jpeg" }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Rate;
