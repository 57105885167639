import React from "react";
import { Position, Size } from "../types/types.ts";
import dogeStaying from "../assets/utochka.png";

interface Props {
  position: Position;
  size: Size;
}

export const Character: React.FC<Props> = (props) => {
  const dogStyle: React.CSSProperties = {
    position: "absolute",
    width: `${props.size.width}px`, // Adjust size as needed
    height: `${props.size.height}px`, // Adjust size as needed
    left: `${props.position.x}px`,
    top: `${props.position.y}px`,
  };

  return (
    <div style={dogStyle}>
      <img src={dogeStaying} alt={"doge-staying"} />
    </div>
  );
};
