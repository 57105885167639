import type React from "react";

import styles from "./button.module.less"

interface Props {
  text: string;
  isDisabled?: boolean;
  onClick: () => void;
  className?: string;
}

export const Button: React.FC<Props> = ({
                                          text,
                                          isDisabled,
                                          onClick,
                                          className,
                                        }) => {
  return (
    <div className={styles.buttonContainer}>
      <button className={className ?? `${styles.acceptButton}`}
              disabled={isDisabled} onClick={onClick}>{text}</button>
    </div>
  );
};
