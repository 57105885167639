import React from "react";
import { Position, Size } from "../types/types.ts";
import platform from "../assets/platform.png";

interface Prop {
  position: Position;
  size: Size;
}

export const Platform: React.FC<Prop> = (prop) => {
  return (
    <div
      style={{
        position: "absolute",
        left: `${prop.position.x}px`,
        top: `${prop.position.y}px`,
        height: `${prop.size.height}px`,
        width: `${prop.size.width}px`,
        background: `url(${platform})`,
      }}
    ></div>
  );
};
