import React from "react";
import { Position, Size } from "../types/types.ts";

interface Props {
  position: Position;
  size: Size;
  isDestroyed: boolean;
}

export const Obstacle: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `${props.position.y}px`,
        left: `${props.position.x}px`,
        width: `${props.size.width}px`,
        height: `${props.size.height}px`,
        display: props.isDestroyed ? "none" : "block",
        background: "black",
      }}
    >
      <span>Obstacle</span>
    </div>
  );
};
