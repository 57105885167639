import React from "react";
import { Position } from "../../doge/types/types.ts";

interface Props {
  position: Position;
}

export const Pin: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `${props.position.y}px`,
        left: `${props.position.x}px`,
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        background: "red",
        border: "1px",
      }}
    >
      <span>some</span>
    </div>
  );
};
