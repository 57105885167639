import React, { useMemo } from "react";
import { Countdown } from "../../../common/components/Countdown/countdown.tsx";
import { useAsync } from "react-async-hook";
import { api } from "../helpers/api.ts";
import { Button } from "./button/button.tsx";

interface Props {
  daysRemaining: number;
  setWelcomeShown: () => void;
}

export const WelcomeCountdown: React.FC<Props> = ({
  daysRemaining,
  setWelcomeShown,
}) => {
  const sendMetric = async () => {
    return await api.metrics.send(metric);
  };
  const metric = useMemo(
    () => ({
      name: "welcome-countdown-shown",
      value: JSON.stringify({
        userTime: new Date().toLocaleString(),
        utcTime: new Date().toUTCString(),
      }),
    }),
    []
  );
  useAsync(sendMetric, [metric], {
    executeOnMount: true,
  });

  return (
    <div
      className="grid h-screen w-screen place-items-center bg-pink-250 text-white z-40"
      style={{ height: "fit-content" }}
    >
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Welcome to Your Surprise!</h1>
        <p className="text-lg">It's gonna be legen... Wait for it... daary!</p>
        <p className={"text-sm italic mb-8"}>(I hope)</p>
        <Countdown daysRemaining={daysRemaining} />
        <Button text="Продолжить" onClick={setWelcomeShown} isDisabled={daysRemaining > 0} />
      </div>
    </div>
  );
};
