import type { Image } from "../../../common/types/media.ts";

export const hints = {
  stage1: { src: "hint1.jpeg" } as Image,
  stage2: { src: "hint2.jpeg" } as Image,
  stage3: { src: "hint3.jpeg" } as Image,
  stage4: { src: "hint4.jpeg" } as Image,
  stage5: { src: "hint5.jpeg" } as Image,
  stage6: { src: "hint6.jpeg" } as Image,
  stage7: { src: "hint7.jpeg" } as Image,
  stage8: { src: "hint8.jpeg" } as Image,
  stage9: { src: "hint9.jpeg" } as Image,
};
