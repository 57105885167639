import type React from "react";

interface Props {
  daysRemaining: number;
}

export const Countdown: React.FC<Props> = ({ daysRemaining }) => {
  return (
    <p className="text-sm italic">
      Your experience coming in a {daysRemaining} days.
    </p>
  );
};
