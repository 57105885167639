import React from "react";

interface Props {
  health: number;
}

export const HealthCounter: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        height: "50px",
        width: "50px",
        background: "white",
        color: "green",
        fontSize: "xx-large",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.health}
    </div>
  );
};
