import React, { useState } from "react";
import { VideoWrapper } from "../../../../common/components/VideoWrapper/video-wrapper";
import { Button } from "../button/button";
import { Congratulation } from "../congratulation/congratulation";
import { useAsyncCallback } from "react-async-hook";
import { Metric } from "../../../../common/types/metrics";
import { api } from "../../helpers/api";
import { getShownMetric } from "../../helpers/metrics";

interface Props {
  index: number;
  countdown: string;
  setIsGiftClaimed: (index: number, value: boolean) => void;
}

const BigGift: React.FC<Props> = ({ index, countdown, setIsGiftClaimed }) => {
  const [isThaiShown, setIsThaiShown] = useState(false);
  const [isOutroShown, setIsOutroChown] = useState(false);
  const [isMainGiftShown, setIsMainGiftShown] = useState(false);
  const sendMetrica = async (metrica: Metric) => {
    const innerMetrica = {
      ...metrica,
    };
    await api.metrics.send(innerMetrica);
  };

  const executeMetrica = useAsyncCallback(sendMetrica);
  const [isThaiShownSended, setIsThaiShownSended] = useState(false);
  const [isCustomCongratsShownSended, setIsCustomCongratsShownSended] =
    useState(false);
  const [isCustomOutroShownSended, setIsCustomOutroShownSended] =
    useState(false);

  const getView = () => {
    if (!isThaiShown) {
      if (!isThaiShownSended) {
        executeMetrica.execute(getShownMetric("thai"));
        setIsThaiShownSended(true);
      }
      return (
        <div style={{ maxHeight: "fit-content" }}>
          <span>
            Не обращай внимания на таймер и жми продолжить, как посмотришь{" "}
            мини-клип :)
            <br />
            New Jeans - Bubble Gum (Lera's Version)
          </span>
          <VideoWrapper
            video={{ src: "/gifts/newjeans.mp4" }}
            poster={{ src: "/gifts/newjeansPoster.jpeg" }}
          />
          <Button
            text="Продолжить"
            onClick={() => {
              executeMetrica.execute({
                name: "set-newjeans-shown",
                value: JSON.stringify({
                  userTime: new Date().toLocaleString(),
                  utcTime: new Date().toUTCString(),
                }),
              });
              setIsThaiShown(true);
            }}
          />
          <span style={{ fontSize: "0.82rem" }}>
            Если видео слишком долго загружается, то его можно посмотреть{" "}
            <a
              href="https://disk.yandex.ru/i/wKmn0b1GbG1zKQ"
              target="_blank"
              style={{ color: "royalblue" }}
            >
              здесь
            </a>
          </span>
        </div>
      );
    }

    if (!isOutroShown) {
      if (!isCustomOutroShownSended) {
        executeMetrica.execute(getShownMetric("custom-outro"));
        setIsCustomOutroShownSended(true);
      }
      return (
        <div style={{ maxHeight: "80%" }}>
          <span>
            Не обращай внимания на таймер и жми продолжить, как посмотришь
            пред-заключительное видео :)
          </span>
          <div
            style={{
              maxHeight: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <VideoWrapper
              isVertical={false}
              poster={{
                src: "/customOutroPoster.jpeg",
              }}
              video={{ src: "/customOutro.mp4" }}
            />
            <Button
              onClick={() => {
                executeMetrica.execute({
                  name: "set-custom-outro-shown",
                  value: JSON.stringify({
                    userTime: new Date().toLocaleString(),
                    utcTime: new Date().toUTCString(),
                  }),
                });
                setIsOutroChown(true);
              }}
              text={"Продолжить"}
            />
            <span style={{ fontSize: "0.82rem" }}>
              Если видео слишком долго загружается, то его можно посмотреть{" "}
              <a
                href="https://disk.yandex.ru/i/NFn6ysnMj4nVfw"
                target="_blank"
                style={{ color: "royalblue" }}
              >
                здесь
              </a>
            </span>
          </div>
        </div>
      );
    }

    if (!isMainGiftShown) {
      if (!isCustomCongratsShownSended) {
        executeMetrica.execute(getShownMetric("custom-congrats"));
        setIsCustomCongratsShownSended(true);
      }
      return (
        <div>
          <span>
            Не обращай внимания на таймер и жми продолжить, как посмотришь на
            моё творчество :)
          </span>
          <Congratulation
            setCongratulationShown={() => {
              executeMetrica.execute({
                name: "set-custom-congrats-shown",
                value: JSON.stringify({
                  userTime: new Date().toLocaleString(),
                  utcTime: new Date().toUTCString(),
                }),
              });
              setIsMainGiftShown(true);
            }}
          />
          <span style={{ fontSize: "0.82rem" }}>
            Если видео слишком долго загружается, то его можно посмотреть{" "}
            <a
              href="https://disk.yandex.ru/i/5JS9_Utbzl4Qtg"
              target="_blank"
              style={{ color: "royalblue" }}
            >
              здесь
            </a>
          </span>
        </div>
      );
    }

    return (
      <Button
        text="Продолжить"
        isDisabled={countdown !== ""}
        onClick={() => setIsGiftClaimed(index, true)}
      />
    );
  };

  return getView();
};

export default BigGift;
