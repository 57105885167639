import React, { useCallback, useEffect, useState } from "react";
import { Character } from "./components/character.tsx";
import { Obstacle } from "./components/obstacle.tsx";
import { Collectible } from "./components/collectible.tsx";
import "./doge.css";
import { Platform } from "./components/platform.tsx";
import { Background } from "./components/background.tsx";
import { HealthCounter } from "./components/HealthCounter.tsx";
import { Position, Rectangle, Size } from "./types/types.ts";
import SafeWrapper from "../../common/components/SafeWrapper/SafeWrapper.tsx";

type HorizontalDirection = "left" | "none" | "right";
type VerticalDirection = "up" | "none" | "down";

enum CollisionSide {
  None = "none",
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left",
}

const horizontalSpeed = 3;
const verticalSpeedUp = 3;
const verticalSpeedRestore = 3;
const maxJumpTime = 700;
const [viewportHeight, viewportWidth] = [
  Math.floor(window.visualViewport?.height || 0),
  Math.floor(window.visualViewport?.width || 0),
];
const baseCharacterPosition: Position = {
  x: 50,
  y: viewportHeight * 0.8 - 20,
};
const characterSize: Size = {
  width: 100,
  height: 74,
};
const [leftLimit, rightLimit] = [
  Math.floor(viewportWidth * 0.1),
  Math.floor(viewportWidth - viewportWidth * 0.35),
];

const gravityPoint = viewportHeight;
const world = {
  name: "First",
  start: {
    position: {
      x: 24,
      y: 0,
    },
    length: 393,
  },
  world: {
    position: {
      x: 381,
      y: 0,
    },
    length: 1290,
  },
  end: {
    position: {
      x: 1562,
      y: 0,
    },
    length: 393,
  },
};

const initialPlatforms: Array<Rectangle> = [
  /// Between first and second islands
  {
    position: { x: world.start.position.x + 370, y: 300 },
    size: { width: 300, height: 20 },
  },
  { position: { x: 30, y: 650 }, size: { width: 100, height: 20 } },
  { position: { x: 130, y: 525 }, size: { width: 100, height: 20 } },
  { position: { x: 210, y: 450 }, size: { width: 100, height: 20 } },
  { position: { x: 410, y: 450 }, size: { width: 100, height: 20 } },
  { position: { x: 610, y: 400 }, size: { width: 300, height: 20 } },
  /// End 1

  /// Between second and third island
  { position: { x: 610, y: 400 }, size: { width: 300, height: 20 } },
  /// End 2

  /// Bottom borders

  {
    position: { x: world.start.position.x - 20, y: viewportHeight * 0.8 + 56 },
    size: { width: 400, height: 20 },
  },
  {
    position: { x: world.start.position.x + 650, y: viewportHeight * 0.8 + 56 },
    size: { width: 400, height: 20 },
  },
  {
    position: {
      x: world.start.position.x + 1650,
      y: viewportHeight * 0.8 + 56,
    },
    size: { width: 400, height: 20 },
  },
  {
    position: {
      x: world.start.position.x + 2050,
      y: viewportHeight * 0.8 + 56,
    },
    size: { width: 400, height: 20 },
  },
  {
    position: {
      x: world.start.position.x + 2650,
      y: viewportHeight * 0.8 + 56,
    },
    size: { width: 600, height: 20 },
  },

  /// End bottom borders

  /// World boundaries
  {
    position: { x: world.start.position.x - 18, y: 0 },
    size: { width: 20, height: 900 },
  }, // Left world limit
  {
    position: { x: 9360 / 3 + 50, y: 0 },
    size: { width: 20, height: 900 },
  }, // Right world limit
  /// End world boundaries
];

const initialObstacles: Array<Rectangle> = [
  {
    position: { x: 600, y: viewportHeight * 0.8 - 100 },
    size: { width: 50, height: 50 },
  },
  {
    position: { x: 700, y: viewportHeight * 0.8 - 100 },
    size: { width: 50, height: 50 },
  },
  {
    position: { x: 800, y: viewportHeight * 0.8 - 100 },
    size: { width: 50, height: 50 },
  },
];

const initialCollectibles: Array<Rectangle> = [
  {
    position: { x: 300, y: viewportHeight * 0.8 - 100 },
    size: { width: 50, height: 50 },
  },
];

export const Doge: React.FC = () => {
  const [showDebug, setShowDebug] = useState(false);

  const [score, setScore] = useState(0);
  const [characterHealth, setCharacterHealth] = useState(3);
  const [characterPosition, setCharacterPosition] = useState(
    baseCharacterPosition
  );
  const [obstacles, setObstacles] =
    useState<Array<Rectangle>>(initialObstacles);
  const [collectibles, setCollectibles] =
    useState<Array<Rectangle>>(initialCollectibles);
  const [collectedCollectiblesIndexes, setCollectedCollectiblesIndexes] =
    useState<Array<number>>([]);
  const [isDestroyedObstaclesIndexes, setIsDestroyedObstaclesIndexes] =
    useState<Array<number>>([]);

  const [isMoving, setMoving] = useState({
    x: "none" as HorizontalDirection,
    y: "none" as VerticalDirection,
  });
  const [jumpTime, setJumpTime] = useState(Date.now);
  const [platforms, setPlatforms] =
    useState<Array<Rectangle>>(initialPlatforms);
  const [worldPosition, setWorldPosition] = useState(0);
  const testCollision = useCallback(
    (other: Rectangle) => {
      const thisCenterX = characterPosition.x + characterSize.width / 2;
      const thisCenterY = characterPosition.y + characterSize.height / 2;
      const otherCenterX = other.position.x + other.size.width / 2;
      const otherCenterY = other.position.y + other.size.height / 2;

      const dx = thisCenterX - otherCenterX;
      const dy = thisCenterY - otherCenterY;
      const halfWidths = (characterSize.width + other.size.width) / 2;
      const halfHeights = (characterSize.height + other.size.height) / 2;

      const xOverlap = halfWidths - Math.abs(dx);
      const yOverlap = halfHeights - Math.abs(dy);

      if (xOverlap > 0 && yOverlap > 0) {
        if (xOverlap >= yOverlap) {
          return dy > 0 ? CollisionSide.Bottom : CollisionSide.Top;
        } else {
          return dx > 0 ? CollisionSide.Left : CollisionSide.Right;
        }
      }

      return CollisionSide.None;
    },
    [characterPosition]
  );

  const [isLeraWon, setIsLeraWon] = useState(false);

  const isCharacterOnTopOfObject = useCallback(() => {
    return (
      platforms.filter((p) => testCollision(p) == CollisionSide.Top).length > 0
    );
  }, [platforms, testCollision]);

  const isCharacterUnderneathOfObject = useCallback(() => {
    return (
      platforms.filter((p) => testCollision(p) == CollisionSide.Bottom).length >
      0
    );
  }, [platforms, testCollision]);

  const isCharacterCollideOnLeftSide = useCallback(() => {
    return (
      platforms.filter((p) => testCollision(p) == CollisionSide.Left).length > 0
    );
  }, [platforms, testCollision]);

  const isCharacterCollideOnRightSide = useCallback(() => {
    return (
      platforms.filter((p) => testCollision(p) == CollisionSide.Right).length >
      0
    );
  }, [platforms, testCollision]);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      if (isLeraWon) return;

      if (worldPosition < -2900) {
        setIsLeraWon(true);
        return;
      }

      // Check if character is collided with collectible, add score and remove collectible
      for (const index in collectibles) {
        if (
          testCollision(collectibles[index]) != CollisionSide.None &&
          !collectedCollectiblesIndexes.includes(Number(index))
        ) {
          setScore((prevScore) => prevScore + 1);
          setCollectedCollectiblesIndexes((prevIndexes) => [
            ...prevIndexes,
            Number(index),
          ]);
          break;
        }
      }

      // Check if character is collided with obstacle, remove obstacle and decrease health
      for (const index in obstacles) {
        if (
          testCollision(obstacles[index]) != CollisionSide.None &&
          !isDestroyedObstaclesIndexes.includes(Number(index))
        ) {
          setCharacterHealth((prevHealth) => prevHealth - 1);
          setIsDestroyedObstaclesIndexes((prevIndexes) => [
            ...prevIndexes,
            Number(index),
          ]);
          break;
        }
      }

      if (characterPosition.y + characterSize.height >= gravityPoint) {
        setCharacterHealth(0);
      }

      if (characterHealth <= 0) {
        // There was an issue with resetting the world position, so I decided to reload the page instead
        // This is not the best solution, but it works
        window.location.reload();
      }

      if (
        characterPosition.y != gravityPoint &&
        isMoving.y == "none" &&
        !isCharacterOnTopOfObject()
      ) {
        if (characterPosition.y > gravityPoint) {
          setCharacterPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y - verticalSpeedRestore,
          }));
        } else {
          setCharacterPosition((prevPosition) => ({
            ...prevPosition,
            y: prevPosition.y + verticalSpeedRestore,
          }));
        }
      }
      if (
        (Date.now() - jumpTime > maxJumpTime ||
          isCharacterUnderneathOfObject()) &&
        isMoving.y == "up"
      ) {
        setMoving((prev) => ({ ...prev, y: "none" }));
      }

      if (
        isMoving.x == "left" &&
        characterPosition.x > leftLimit &&
        !isCharacterCollideOnLeftSide()
      ) {
        setCharacterPosition((prevPosition) => ({
          ...prevPosition,
          x: prevPosition.x - horizontalSpeed,
        }));
      } else if (isMoving.x == "left" && !isCharacterCollideOnLeftSide()) {
        const newPlatforms = [...platforms];
        newPlatforms.map(
          (platform) => (platform.position.x += horizontalSpeed)
        );
        setPlatforms(newPlatforms);
        const newCollectibles = [...collectibles];
        newCollectibles.map(
          (collectible) => (collectible.position.x += horizontalSpeed)
        );
        setCollectibles(newCollectibles);
        const newObstacles = [...obstacles];
        newObstacles.map(
          (obstacle) => (obstacle.position.x += horizontalSpeed)
        );
        setObstacles(newObstacles);
        setWorldPosition((prevState) => prevState + horizontalSpeed);
      }

      if (
        isMoving.x == "right" &&
        characterPosition.x + characterSize.width < rightLimit &&
        !isCharacterCollideOnRightSide()
      ) {
        setCharacterPosition((prevPosition) => ({
          ...prevPosition,
          x: prevPosition.x + horizontalSpeed,
        }));
      } else if (isMoving.x == "right" && !isCharacterCollideOnRightSide()) {
        if (characterPosition.x + characterSize.width < rightLimit) {
          setCharacterPosition((prevPosition) => ({
            ...prevPosition,
            x: prevPosition.x + horizontalSpeed,
          }));
        } else {
          const newPlatforms = [...platforms];
          newPlatforms.map(
            (platform) => (platform.position.x -= horizontalSpeed)
          );
          setPlatforms(newPlatforms);
          const newCollectibles = [...collectibles];
          newCollectibles.map(
            (collectible) => (collectible.position.x -= horizontalSpeed)
          );
          setCollectibles(newCollectibles);
          const newObstacles = [...obstacles];
          newObstacles.map(
            (obstacle) => (obstacle.position.x -= horizontalSpeed)
          );
          setObstacles(newObstacles);
          setWorldPosition((prevState) => prevState - horizontalSpeed);
        }
      }

      if (isMoving.y == "up" && !isCharacterUnderneathOfObject()) {
        setCharacterPosition((prevPosition) => ({
          ...prevPosition,
          y: prevPosition.y - verticalSpeedUp,
        }));
      }
    }, 10);

    return () => clearInterval(gameLoop);
  }, [
    obstacles,
    isDestroyedObstaclesIndexes,
    collectibles,
    collectedCollectiblesIndexes,
    isMoving,
    characterPosition,
    jumpTime,
    platforms,
    characterHealth,
    isCharacterOnTopOfObject,
    isCharacterUnderneathOfObject,
    isCharacterCollideOnRightSide,
    isCharacterCollideOnLeftSide,
    testCollision,
    isLeraWon,
    worldPosition,
  ]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "a" || "ф":
        setMoving((prev) => ({ ...prev, x: "left" }));
        break;
      case "d" || "в":
        setMoving((prev) => ({ ...prev, x: "right" }));
        break;
      case " ":
        if (
          isMoving.y != "up" &&
          Date.now() - jumpTime > maxJumpTime &&
          !event.repeat
        ) {
          setJumpTime(Date.now);
          setMoving((prev) => ({ ...prev, y: "up" }));
        } else {
          setMoving((prev) => ({ ...prev, y: "none" }));
        }
        break;
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "a":
        setMoving((prev) => ({ ...prev, x: "none" }));
        break;
      case "d":
        setMoving((prev) => ({ ...prev, x: "none" }));
        break;
      case " ":
        setMoving((prev) => ({ ...prev, y: "none" }));
        break;
    }
  };

  const handleOnMovement = (direction: HorizontalDirection) => {
    setMoving((prev) => ({ ...prev, x: direction }));
  };

  const handleOnJump = (direction: VerticalDirection) => {
    if (isMoving.y != "up" && Date.now() - jumpTime > maxJumpTime) {
      setJumpTime(Date.now);
      setMoving((prev) => ({ ...prev, y: direction }));
    } else {
      setMoving((prev) => ({ ...prev, y: direction }));
    }
  };

  return !isLeraWon ? (
    <div
      className={"game-container"}
      tabIndex={0}
      onKeyDown={handleKeyPress}
      onKeyUp={handleKeyUp}
    >
      <SafeWrapper>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            flexGrow: 1,
          }}
        >
          <div className={"score"}>
            <span>{score}</span>
          </div>

          <HealthCounter health={characterHealth} />

          <div
            style={{
              backgroundColor: "black",
              width: 200,
              height: 300,
              display: showDebug ? "flex" : "none",
              flexDirection: "column",
              color: "white",
              zIndex: 1000,
            }}
          >
            <span>Debug Info</span>
            <span>vw: {window.visualViewport?.width ?? 0}</span>
            <span>vh: {window.visualViewport?.height ?? 0}</span>
            <span>ih: {window.innerHeight}</span>
            <span>iw: {window.innerWidth}</span>
            <span>sh: {window.screen.height}</span>
            <span>sw: {window.screen.width}</span>
            <span>sah: {window.screen.availHeight}</span>
            <span>saw: {window.screen.availWidth}</span>
            <span>
              hero-pos: {characterPosition.x}, {characterPosition.y}
            </span>
            <span>world-pos: {worldPosition}</span>
          </div>
        </div>
        <div className={"buttons-container"}>
          <div
            className={"move-button"}
            onMouseDown={() => handleOnMovement("left")}
            onMouseUp={() => handleOnMovement("none")}
            onTouchStart={() => handleOnMovement("left")}
            onTouchEnd={() => handleOnMovement("none")}
          >
            {"<"}
          </div>
          <div
            className={"move-button"}
            onMouseDown={() => handleOnJump("up")}
            onMouseUp={() => handleOnJump("none")}
            onTouchStart={() => handleOnJump("up")}
            onTouchEnd={() => handleOnJump("none")}
          >
            {"^"}
          </div>
          <div
            className={"move-button"}
            onMouseDown={() => handleOnMovement("right")}
            onMouseUp={() => handleOnMovement("none")}
            onTouchStart={() => handleOnMovement("right")}
            onTouchEnd={() => handleOnMovement("none")}
          >
            {">"}
          </div>
          <div
            className={"move-button"}
            onClick={() => setShowDebug(!showDebug)}
          >
            D
          </div>
        </div>
      </SafeWrapper>

      {/* Render game elements */}
      <>
        <Background
          vh={viewportHeight}
          world={{
            ...world,
            currentHeroPosition: worldPosition,
          }}
        />
        <Character position={characterPosition} size={characterSize} />
        {obstacles.map((obstacle, index) => (
          <Obstacle
            key={index}
            position={obstacle.position}
            size={obstacle.size}
            isDestroyed={isDestroyedObstaclesIndexes.includes(index)}
          />
        ))}
        {collectibles.map((collectible, index) => (
          <Collectible
            key={index}
            position={collectible.position}
            size={collectible.size}
            isCollected={collectedCollectiblesIndexes.includes(index)}
          />
        ))}
        {platforms.map((platform, i) => (
          <Platform key={i} position={platform.position} size={platform.size} />
        ))}
      </>
    </div>
  ) : (
    <div
      style={{
        display: "table",
      }}
    >
      <div
        style={{
          display: "table-cell",
          verticalAlign: "middle",
        }}
      >
        <span
          style={{
            fontSize: "34px",
            color: "black",
          }}
        >
          You won!
        </span>
      </div>
    </div>
  );
};
