import React from "react";
import { Button } from "../button/button";

interface Props {
  text: string;
  setGiftClaimed: () => void;
  isEnabled: boolean;
}

const Gift: React.FC<Props> = ({ text, setGiftClaimed, isEnabled }) => {
  return (
    <>
      <span>{text}</span>
      <Button text={"Продолжить"} onClick={setGiftClaimed} isDisabled={!isEnabled} />
    </>
  );
};

export default Gift;
