import React, { useState } from "react";
import { levels } from "./levels.ts";
import { CrosswordView } from "./crosswordView.tsx";
import "./styles/crossword.css";

export const Crossword: React.FC = () => {
  const [level, setLevel] = useState<number>(0);

  return (
    <div className={"h-screen w-screen place-items-center"}>
      <span>CROSSWORD</span>
      <div className={"crossword-container"}>
        <div className={"crossword"}>
          <CrosswordView
            level={levels[level]}
            onCompleted={() => setLevel(level + 1)}
          />
        </div>
        <div className={"questions"}>
          <span>asd</span>
        </div>
      </div>
    </div>
  );
};
