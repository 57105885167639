import type React from "react";
import type { Image, Video } from "../../types/media.ts";

import styles from "./video-wrapper.module.less";

interface Props {
  isVertical?: boolean;
  poster?: Image;
  video: Video;
}

export const VideoWrapper: React.FC<Props> = ({
  poster,
  video,
}) => {
  return (
    <div className={styles.videoWrapper}>
      <video
        loop={false}
        preload={"auto"}
        controls={true}
        src={video.src}
        poster={poster?.src ?? ""}
      ></video>
    </div>
  );
};
