export const extractQueryParams = (url: string): Record<string, string> => {
  const params = new URLSearchParams(new URL(url).hash.split("?")[1]);
  const paramsObject: Record<string, string> = {};

  params.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
}
