import React, { useState } from "react";
import { Level } from "./types/level.ts";

interface Props {
  level: Level;
  onCompleted: () => void;
}

export const CrosswordView: React.FC<Props> = (props) => {
  console.error(props);

  return (
    <div>
      <span>Cross</span>
    </div>
  );
};
