import { useEffect, useState } from "react";
import { storage } from "../utils/storage.ts";

export class LocalStorage<T> {
  constructor(private key: string, private defaultValue: T) {
  }

  getValue(): T {
    try {
      const item = storage.getItem(this.key);
      if (!item) storage.setItem(this.key, JSON.stringify(this.defaultValue));
      return item ? JSON.parse(item) : this.defaultValue;
    } catch (error) {
      console.error(error);
      return this.defaultValue;
    }
  }

  setValue(value: T) {
    try {
      storage.setItem(this.key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  }
}

function useLocalStorage<T>(keySuffix: string, defaultValue: T): [T, (value: T) => void] {
  const key = `prelerstochka.${keySuffix}`;
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      if (!item) storage.setItem(key, JSON.stringify(defaultValue));
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.error(error);
      return defaultValue;
    }
  });
  const setValue = (value: T) => {
    try {
      storage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
      const storageEvent = new StorageEvent('storage', {
        key: key,
        newValue: JSON.stringify(value),
        oldValue: JSON.stringify(storedValue),
        storageArea: localStorage,
      });
      window.dispatchEvent(storageEvent);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        console.log("Storage changed");
        try {
          setStoredValue(event.newValue ? JSON.parse(event.newValue) : defaultValue);
        } catch (error) {
          console.error(error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, defaultValue]);

  return [storedValue, setValue];
}

export default useLocalStorage;
