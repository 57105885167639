import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import { HashRouter } from "react-router-dom";
import createLogger from "./common/utils/logger.ts";
import { api } from "./features/anniversary/helpers/api.ts";

const version = "0.4.3";
console.debug(version);
api.metrics.send({
  name: "log",
  value: `Application version: ${version}`,
});

/// ==== Safari iOS hack -- disable pinch-to-zoom and pinch-to-tabs
document.addEventListener("gesturestart", function (e) {
  e.preventDefault();
});

document.addEventListener("gesturechange", function (e) {
  e.preventDefault();
});

document.addEventListener("gestureend", function (e) {
  e.preventDefault();
});
// ====

createLogger(false);

let updated = false;
let activated = false;
navigator.serviceWorker.getRegistrations().then((registrations) => {
  for (const registration of registrations) {
    registration.addEventListener("updatefound", () => {
      const worker = registration.installing;
      if (!worker) return;
      worker.addEventListener("statechange", () => {
        console.debug({ state: worker.state });
        if (worker.state === "activated") {
          activated = true;
          checkUpdate();
        }
      });
      registration.update();
    });
  }
});
navigator.serviceWorker.addEventListener("controllerchange", () => {
  console.debug({ state: "updated" });
  updated = true;
  checkUpdate();
});

const checkUpdate = () => {
  if (activated && updated) {
    console.debug("Application was updated refreshing the page...");
    api.metrics.send({
      name: "log",
      value: `Application was updated from version ${version}`,
    });
    window.location.reload();
  }
};

const nothing = () => {
  console.debug("ACTION IS DOING NOTHING — USED ONLY FOR ENFORCE UPDATE SW");
}

const indexes = [0,1,2,3,4,5];
for (const i of indexes) {
  console.debug("DOING NOTHING: " + i);
  nothing();
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <DevSupport
      ComponentPreviews={ComponentPreviews}
      useInitialHook={useInitial}
    >
      <HashRouter>
        <App />
      </HashRouter>
    </DevSupport>
  </React.StrictMode>
);
