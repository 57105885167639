import type React from "react";
import { useMemo } from "react";
import { VideoWrapper } from "../../../../common/components/VideoWrapper/video-wrapper.tsx";
import { Button } from "../button/button.tsx";
import { api } from "../../helpers/api.ts";
import { useAsync } from "react-async-hook";
import { getShownMetric } from "../../helpers/metrics.ts";

import styles from "./introduction.module.less";

interface Props {
  setIntroductionShown: () => void;
}

export const Introduction: React.FC<Props> = ({ setIntroductionShown }) => {
  const sendMetric = async () => {
    return await api.metrics.send(metric);
  };
  const metric = useMemo(() => getShownMetric("introduction"), []);
  useAsync(sendMetric, [metric], {
    executeOnMount: true,
  });

  return (
    <div className={styles.introContainer}>
      <VideoWrapper
        isVertical={false}
        poster={{ src: "/introPoster.png" }}
        video={{ src: "/intro.mp4" }}
      />
      <Button onClick={setIntroductionShown} text={"Продолжить"} />
    </div>
  );
};
