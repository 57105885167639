import axios from "axios";
import { getUser } from "./user.ts";

const simpleCache: Map<string, Blob> = new Map();

export const BaseApi = {
  loadAsset: (path: string): Promise<Blob> => {
    if (simpleCache.has(path)) return Promise.resolve(simpleCache.get(path)!);
    return axios
      .get<Blob>(`/api/file/download?filename=${path}`, {
        responseType: "blob",
      })
      .then((response) => {
        simpleCache.set(path, response.data);
        return response.data;
      });
  },
  messageMe: (): Promise<void> => {
    return axios.post(`/care?user=${getUser()}`, {});
  }
};
