const storageAvailable = function (type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

export interface Storage {
  /**
   * Empties the list associated with the object of all key/value pairs, if there are any.
   */
  clear(): void;

  /**
   * Returns the current value associated with the given key, or null if the given key does not exist in the list associated with the object.
   */
  getItem(key: string): string | null;

  /**
   * Returns the name of the nth key in the list, or null if n is greater than or equal to the number of key/value pairs in the object.
   */
  removeItem(key: string): void;

  /**
   * Sets the value of the pair identified by key to value, creating a new key/value pair if none existed for key previously.
   *
   * Throws a "QuotaExceededError" DOMException exception if the new value couldn't be set. (Setting could fail if, e.g., the user has disabled storage for the site, or if the quota has been exceeded.)
   */
  setItem(key: string, value: string): void;
}

class loggingWrapper implements Storage {
  private readonly implementation: Storage;
  private readonly storageName: string;

  constructor(implementation: Storage, storageName: string) {
    this.implementation = implementation;
    this.storageName = storageName;
  }

  clear(): void {
    console.info(`[${this.storageName}] clear storage`);
    try {
      this.implementation.clear();
    } catch (e) {
      console.error(`[${this.storageName}] clear storage error`, e);
      throw e;
    }
  }

  getItem(key: string): string | null {
    try {
      const value = this.implementation.getItem(key);
      console.info(`[${this.storageName}] get item`, { key, value });
      return value;
    } catch (e) {
      console.error(`[${this.storageName}] get item error`, e, { key });
      throw e;
    }
  }

  removeItem(key: string): void {
    console.info(`[${this.storageName}] remove item`, { key });
    try {
      this.implementation.removeItem(key);
    } catch (e) {
      console.error(`[${this.storageName}] remove item error`, e);
      throw e;
    }
  }

  setItem(key: string, value: string): void {
    console.info(`[${this.storageName}] set item`, { key, value });
    try {
      this.implementation.setItem(key, value);
    } catch (e) {
      console.error(`[${this.storageName}] set item error`, e);
      throw e;
    }
  }
}

class fakeStorage implements Storage {
  storage = {};

  clear(): void {
    this.storage = {};
  }

  getItem(key: string): string | null {
    return this.storage[key];
  }

  removeItem(key: string): void {
    delete this.storage[key];
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }
}

export const storage = storageAvailable("localStorage")
  ? new loggingWrapper(window.localStorage, "localStorage")
  : new loggingWrapper(new fakeStorage(), "fakeStorage");
