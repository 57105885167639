import type React from "react";

import { Path } from "path-parser";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Crossword } from "../../features/crossword/crossword.tsx";
import { Home } from "../../features/home/home.tsx";
import { OurJourney } from "../../features/our-journey/our-journey.tsx";
import { Introduction } from "../../features/introduction/introduction.tsx";
import { Doge } from "../../features/doge/doge.tsx";
import { Anniversary } from "../../features/anniversary/anniversary.tsx";
import Administration
  from "../../features/anniversary/components/administration/administration.tsx";

export interface Route<K extends string, N extends string> {
  build: (params?: Record<K, string>, encodeParams?: boolean) => string;
  name?: N;
  beforeEnter?: (params: Record<K, string>) => void;
  element?: React.ReactElement;
}

export const route = <K extends string, N extends string>(route: Route<K, N>) =>
  ({
    [route.name as string]: {
      ...route,
      element: route.element ?? null,
    },
  }) as { [P in N]: Route<K, N> };

const buildPath = <K extends string>(
  params: { [key in K]: string },
  s: ArrayLike<string>,
  keys: K[],
  encodeParams: boolean = true,
) => {
  const queryParameterIsEmpty = (v: string) => v === null || v === void 0;
  return keys.reduce((result: string, key: K, i: number) => {
    const isQueryParameter = result.includes("?");
    const value = params[key];
    if (isQueryParameter) {
      if (queryParameterIsEmpty(value)) {
        return result;
      }
      const isFirstQueryParameter = result.endsWith("?");
      const replacement =
        (isFirstQueryParameter ? "" : "&") +
        `${key}=${encodeParams ? encodeURIComponent(value) : value}`;
      return result + replacement;
    }
    return (
      result + (encodeParams ? encodeURIComponent(value) : value) + s[i + 1]
    );
  }, s[0]);
};

const buildTemplate = <K extends string>(s: ArrayLike<string>, keys: K[]) =>
  keys.reduce((result: string, key: K, i: number) => {
    const isQueryParameter = result.includes("?");
    const replacement =
      ":" + key + (isQueryParameter ? "" : "<[a-zA-Z0-9-_.~%':|=+\\*@]+>");
    return result + replacement + s[i + 1];
  }, s[0]);

export const path = <K extends string>(s: ArrayLike<string>, ...keys: K[]) => {
  return (params?: { [key in K]: string }, encodeParams?: boolean) =>
    params ? buildPath(params, s, keys, encodeParams) : buildTemplate(s, keys);
};

export const routes = {
  ...route({ build: path``, name: "index", element: <Home /> }),
  ...route({
    build: path`features/introduction`,
    name: "introduction",
    element: <Introduction />,
  }),
  ...route({
    build: path`features/crossword`,
    name: "crossword",
    element: <Crossword />,
  }),
  ...route({
    build: path`features/our-journey`,
    name: "our-journey",
    element: <OurJourney />,
  }),
  ...route({
    build: path`features/doge`,
    name: "doge",
    element: <Doge />,
  }),
  ...route({
    build: path`features/anniversary`,
    name: "anniversary",
    element: <Anniversary />,
  }),
  ...route({
    build: path`features/anniversary/administration`,
    name: "anniversaryAdministration",
    element: <Administration />,
  })
};

export type Routes = keyof typeof routes;

export const useNavigator = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  function navigateTo(route: Routes, params?: Record<string, string>) {
    const targetRoute = routes[route];
    const prevPath = location.pathname.slice(1) + location.search;
    const prevRoute = routes[location.pathname.slice(1)];
    const pathParser =
      prevRoute !== undefined ? new Path(prevRoute.build()) : null;
    const prevParams = pathParser?.test(prevPath) ?? {};
    const mergedParams = { ...prevParams, ...params };
    const newRoutePath = targetRoute.build(mergedParams);

    navigate(newRoutePath);
  }

  function setQueryParam(name: string, value: string): void {
    if (searchParams.get(name) === value) return;

    searchParams.set(name, value);
    setSearchParams(searchParams);
  }

  function getQueryParam<K extends Routes>(
    //@ts-expect-error I know what I'm doing
    route: K,
    name: string,
  ): string | null {
    return searchParams.get(name);
  }

  return {
    navigateTo,
    setQueryParam,
    getQueryParam,
    location,
  };
};
