import React, { useEffect, useState } from "react";

export const Introduction: React.FC = () => {
  const startDate = new Date("2023-12-29");
  const [countdown, setCountdown] = useState<number>(
    Math.floor((startDate.getTime() - Date.now()) / 1000)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const daysRemaining = Math.floor(countdown / (24 * 60 * 60));

  return (
    <div className="grid h-screen w-screen place-items-center bg-gray-800 text-white">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">
          Welcome to Your Immersive Experience!
        </h1>
        <p className="text-lg mb-8">
          It gonna be legen... Wait for it... daary!
        </p>
        <p className="text-sm italic">
          Something magical coming in a {daysRemaining} days.
        </p>
      </div>
    </div>
  );
};
