import type React from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./routes.tsx";

export const ApplicationRouter: React.FC = () => {
  return (
    <Routes>
      {Object.values(routes).map((route) => (
        <Route
          key={route.name}
          path={route.build()}
          element={
            route.element ?? <span>There is no element fot that path</span>
          }
        />
      ))}
    </Routes>
  );
};
